/* print.css */

@media print, (max-width: 1px) {
  /* 隐藏不需要打印的元素 */
  .no-print {
    display: none !important;
  }

  /* 确保页面背景为白色 */
  body {
    background-color: white !important;
  }

  /* 调整页面标题样式 */
  h1 {
    font-size: 14pt;
    margin-bottom: 10pt;
  }

  /* 调整图表容器 */
  .chart-container {
    width: 100% !important;
    height: 85vh !important; /* 调整这个值来改变图表高度 */
    page-break-inside: avoid;
    margin: 0;
    padding: 0;
  }

  /* 最新一期中奖号样式 */
  .latest-draw {
    font-size: 10pt;
    font-weight: bold;
    margin-bottom: 10pt;
  }

  /* 确保图表在打印时清晰可见 */
  canvas {
    max-width: 100% !important;
    height: auto !important;
    max-height: none !important;
  }

  /* 减少其他元素的边距和填充 */
  body {
    margin: 0;
    padding: 0;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  /* 隐藏可能占用空间的不必要元素 */
  .no-print {
    display: none !important;
  }

}
  

